import React, { createContext, useContext, useState, } from "react";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

export const TnetContext = createContext([]);
let FetchLock = false;
export function UseTnetContext({ children }) {

  const [logs, setLogs] = useState([])
  const [contactNumber,setContact] = useState('11941867789')
  
  const [products, setProducts] = useState([
    {
      category: 'Policial',
      name: 'Tablet policial',
      slogan: 'Sistema para investigação policial Sistema para investigação policial Sistema para investigação policial',
      description: 'Lorem mt ipsum mt ipsum loren John Doe hellow world Lorem mt ipsum mt ipsum loren John Doe hellow world Lorem mt ipsum mt ipsum loren John Doe hellow world',
      thumbnail: 'https://upload.wikimedia.org/wikipedia/commons/thumb/2/28/Duck_wings_outstretched.jpg/1200px-Duck_wings_outstretched.jpg',
      preview: 'https://chatgpt.com/c/67241b7b-8074-8001-b354-bba9269d02ed',
      images: [
        'https://upload.wikimedia.org/wikipedia/commons/thumb/2/28/Duck_wings_outstretched.jpg/1200px-Duck_wings_outstretched.jpg',
        'https://www.petz.com.br/blog/wp-content/uploads/2021/08/pato-de-estimacao3.jpg'
      ]
    },
    {
      category: 'Policial',
      name: 'Tablet policial',
      slogan: 'Sistema para investigação policial Sistema para investigação policial Sistema para investigação policial',
      description: 'Lorem mt ipsum mt ipsum loren John Doe hellow world Lorem mt ipsum mt ipsum loren John Doe hellow world Lorem mt ipsum mt ipsum loren John Doe hellow world',
      thumbnail: 'https://upload.wikimedia.org/wikipedia/commons/thumb/2/28/Duck_wings_outstretched.jpg/1200px-Duck_wings_outstretched.jpg',
      images: [
        'https://upload.wikimedia.org/wikipedia/commons/thumb/2/28/Duck_wings_outstretched.jpg/1200px-Duck_wings_outstretched.jpg',
        'https://www.petz.com.br/blog/wp-content/uploads/2021/08/pato-de-estimacao3.jpg'
      ]
    },
  ])

  const [comercialProducts, setComercialProducts] = useState([])
  

  const [defaultComercialProducts,setDefaultComercial] = useState({
    bases : ['tNet'],
    scripts: ['Lavagem','Espectador']
  })

  const generateProducts = (ecs) => {
    const comercialProducts = [
      ...ecs.bases.map((base) => ({ label: base, type: 'base' })),
      ...ecs.scripts.map((script) => ({ label: script, type: 'script' })),
    ];
    setComercialProducts(comercialProducts)
  }

  useEffect(async () => {
    const data = await window.sendRequest('/api/listProducts');
    setDefaultComercial(data)
    generateProducts(data)

  }, []);




  const [infoCards, setInfoCards] = useState([
    
    {
      label: 'Porque escolher nossa base?',
      description : 'Voce deve comprar por x,y,z,z, motivos dsadsa dsa dsa s',
      topics: [ 
        {
          label: "Otimizacao",
          icon : 'AiFillBoxPlot',
        },
        {
          label: "Suporte 24/7",
          icon : 'AiFillAndroid',
        },
      ],
      media: {
        type: 'video',
        link: 'https://www.youtube.com/embed/MZp6KjfXMDs?si=5QMPVttG4fW6lsMd'
      }
    },

  ])
 
  function addLog(category, message,date,id) {
    setLogs(prevLogs => [...prevLogs, {
      id,
      date,
      category,
      message
    }]);
  }
  window.fetchLogs = (filter,clear)=> {
    if(FetchLock) {
      return;
    }
    FetchLock=true;
    if (clear) {
      setLogs([]);
    }

    let params;
    if (filter) {
      params = new URLSearchParams({
        filter,
        index: (logs.length != 0) && !clear ? logs[logs.length-1].id : 4294967295
      });
    } else {
      params = new URLSearchParams({
        index:(logs.length != 0) && !clear? logs[logs.length-1].id : 4294967295
      });
    }
     window.sendRequest(`/api/logs?${params.toString()}`).then((arr)=>{
      for (let d of arr) {
        addLog('Licensa', d.mensagem,new Date(d.time_sent.secs_since_epoch*1000),d.id);
      }
      FetchLock=false;
    }).catch(({errorCode})=>{
      window.TriggerError(errorCode)
    }) 
  };
  


  
  /* 
  
    {
      date: new Date(),
      category: 'licensa',
      message: 'Joao deu um bezerro 1'
    },
    {
      date: new Date(),
      category: 'licensa',
      message: 'Joao deu um bezerro 2'
    },
    {
      date: new Date(),
      category: 'licensa',
      message: 'Joao deu um bezerro 3'
    },
    {
      date: new Date(),
      category: 'licensa',
      message: 'Joao deu um bezerro 4'
    },
    {
      date: new Date(),
      category: 'licensa',
      message: 'Joao deu um bezerro 5'
    },
    {
      date: new Date(),
      category: 'licensa',
      message: 'Joao deu um bezerro 6'
    },
    {
      date: new Date(),
      category: 'licensa',
      message: 'Joao deu um bezerro 7'
    },
    {
      date: new Date(),
      category: 'licensa',
      message: 'Joao deu um bezerro 8'
    },
    {
      date: new Date(),
      category: 'licensa',
      message: 'Joao deu um bezerro 9'
    },
    {
      date: new Date(),
      category: 'licensa',
      message: 'Joao deu um bezerro 10'
    },
    {
      date: new Date(),
      category: 'licensa',
      message: 'Joao deu um bezerro 11'
    },
    {
      date: new Date(),
      category: 'licensa',
      message: 'Joao deu um bezerro 12'
    },
    {
      date: new Date(),
      category: 'licensa',
      message: 'Joao deu um bezerro 13'
    },
    {
      date: new Date(),
      category: 'licensa',
      message: 'Joao deu um bezerro 14'
    },
    {
      date: new Date(),
      category: 'licensa',
      message: 'Joao deu um bezerro 15'
    },
    {
      date: new Date(),
      category: 'licensa',
      message: 'Joao deu um bezerro 16'
    },
    {
      date: new Date(),
      category: 'licensa',
      message: 'Joao deu um bezerro 17'
    },
    {
      date: new Date(),
      category: 'licensa',
      message: 'Joao deu um bezerro 18'
    },
    {
      date: new Date(),
      category: 'licensa',
      message: 'Joao deu um bezerro 19'
    },
    {
      date: new Date(),
      category: 'licensa',
      message: 'Joao deu um bezerro 20'
    },
    {
      date: new Date(),
      category: 'licensa',
      message: 'Joao deu um bezerro 21'
    },
    {
      date: new Date(),
      category: 'licensa',
      message: 'Joao deu um bezerro 22'
    },
    {
      date: new Date(),
      category: 'licensa',
      message: 'Joao deu um bezerro 23'
    },
    {
      date: new Date(),
      category: 'licensa',
      message: 'Joao deu um bezerro 24'
    },
  
  */

  // Função para formatar um objeto Date no formato "Mar 31, 17:40:40 2024"
  function formatDate(date) {
    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const month = months[date.getMonth()];

    const day = String(date.getDate()).padStart(2, ' '); // Para garantir que o dia tenha 2 caracteres
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
    const year = date.getFullYear();

    const formattedDate = `${month} ${day}, ${hours}:${minutes}:${seconds} ${year}`;
    return formattedDate;
  }

  const [isModalNewUser, setisModalNewUser] = useState(false)
  const [isModalNewMachine, setModalNewMachine] = useState(false)
  const [ipManagement, setIpManagement] = useState(false)
  const [activeClient, setActiveClient] = useState('')


  useEffect(() => {
    resetForms()
  }, [isModalNewUser, isModalNewMachine]);

  const [userForms, setUserForms] = useState({
    Nome: '',
    Email: '',
    Telefone: '',
    Discord: '',
  })

  function resetForms() {
    window.clearDrafts()

    setUserForms({
      Nome: '',
      Email: '',
      Telefone: '',
      Discord: '',
    })
    setActiveClient('')
  }

  function formatPrice(value) {
    let number = parseFloat(value);

    // Verifica se o valor é um número válido
    if (isNaN(number)) {
      return 'Invalid value';
    }

    // Formata o número como uma string com separadores de milhares e duas casas decimais
    return number.toLocaleString('pt-BR', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  }
  const [playersConnected, setPlayersConnected] = useState(500)

  const [sellers, setSellers] = useState({
    amount: 0,
    sales: [
      {
        date: '29/06/2024',
        price: 5000,
      },
    ],
  });

  useEffect(() => {
    const calculateTotalAmount = () => {
      let value = 0;
      sellers.sales.forEach((e) => {
        value += e.price;
      });
      setSellers((prevSellers) => ({
        ...prevSellers,
        amount: value,
      }));
      console.log('sales', value)
    };

    calculateTotalAmount();
  }, [sellers.sales]); // Apenas depende de sellers.sales para evitar loop infinito


  /*
      {
      Discord: '275693670986940416',
      Email: 'trigueiro@gmail.com',
      Telefone: '11941867789',
      Nome: 'Luiz',
      Machines: [
        {
          ip: '127.0.0.1',
          port: 30120,
          token: 'dDedASDSAX342',
          status: true,
          players: 30,
          machineTest: 70
        }
      ]
    },
    {
      Discord: '275693670986930416',
      Email: 'nickolas@gmail.com',
      Telefone: '11941867789',
      Nome: 'Nickolas',
      Machines: [
        {
          ip: '127.0.0.1',
          port: 30120,
          token: 'dDe2SAX344342A',
          status: true,
          players: 30,
          machineTest: 70
        }
      ]
    },
  */
  const [users, setUsers] = useState([{
    Discord: '275693670986940416',
    Email: 'trigueiro@gmail.com',
    Telefone: '11941867789',
    Nome: 'Luiz',
    Machines: [
      {
        ip: '127.0.0.1',
        port: 30120,
        token: 'dDedASDSAX342',
        status: true,
        players: 30,
        machineTest: 70
      }
    ]
  },
  {
    Discord: '275693670986930416',
    Email: 'nickolas@gmail.com',
    Telefone: '11941867789',
    Nome: 'Nickolas',
    Machines: [
      {
        ip: '127.0.0.1',
        port: 30120,
        token: 'dDe2SAX344342A',
        status: true,
        players: 30,
        machineTest: 70
      }
    ]
  },])
  window.refreshUsers=(async ()=>{
      let data = await window.sendRequest('api/getUsers')
      setUsers(data.map((data)=>{
          return {...data,Discord:data.DiscordId,Machines:[]}
      }))
  }); 
  useEffect(()=>{
     window.refreshUsers() 
  },[]) 


  function gerarLinkWhatsApp(texto) {
    const textoCodificado = encodeURIComponent(texto);
    const link = `https://wa.me/${contactNumber}?text=${textoCodificado}`;
    
    return link;
}

  return (
    <TnetContext.Provider
      value={{
        logs,
        gerarLinkWhatsApp,
        contactNumber,
        products,
        setProducts,
        addLog,
        formatDate,
        userForms,
        setUserForms,
        resetForms,
        users,
        setUsers,
        isModalNewUser,
        setisModalNewUser,
        isModalNewMachine,
        setModalNewMachine,
        ipManagement,
        setIpManagement,
        activeClient,
        setActiveClient,
        sellers,
        setSellers,
        formatPrice,
        playersConnected,
        comercialProducts,
        infoCards
      }}
    >
      {children}
    </TnetContext.Provider>
  );
};

export const UseTnetCtx = () => useContext(TnetContext);
