export default async function sendWebhook(url, data) {
    let embeds = []; // Inicializa o array de embeds

   
    let description = [];

 
    Object.entries(data.group).forEach(([key, value]) => {
        description.push(`**${key}:** ${value}`); 
    });

    embeds.push({
        title: data.Titulo,
        description: description.join('\n'),
    });

    const webhookPayload = {
        content: 'Novo formulário submetido! @here',
        embeds,
    };

    await fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(webhookPayload),
    });
}
