export default function Card({handler, data}) {    
 

    const {
        category,
        name,
        slogan,
        description,
        images,
        thumbnail,
    } = data
    
    return (
        <div
            onClick={()=> handler(data)}
            className="z-30 hover:text-white w-[352px] h-[472px] bg-[#F9F8F8] px-[24px] pt-[24px] pb-[32px] flex flex-col gap-8 rounded-xl hover:bg-[#ff0000b2] hover:cursor-pointer">
            <header>
                <img className="w-full h-[240px] bg-red-500" src={thumbnail} alt="thumbnail do sistema" />

            </header>
            <footer>
                <p className="text-[#C64141] text-[14px] font-semibold">{category}</p>
                <div className="flex justify-between items-center">
                    <h1 className="text-[24px] font-semibold">{name}</h1>
                    <span>
                        <svg className="w-[24px] h-[24px]" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M7 17L17 7M17 7H7M17 7V17" stroke="#101828" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                    </span>
                </div>
                <p className="title text-[16px] font-normal mt-[16px]">
                    {slogan}
                </p>
            </footer>
        </div>
    )
}