export default function Title() {
    return (
        <div className="relative flex items-center flex-col mt-[87px] bgtest text-center">
            <img className="absolute max-w-[500%] z-[0] " src="Mack.png" alt="logo da base tnet" />
            <div className="flex m-auto items-center rounded-[32px] bg-[#ff00007f] w-[277px] px-[6px]">
                <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path  d="M9.5 6.5C9.5 6.32741 9.36009 6.1875 9.1875 6.1875C8.15196 6.1875 7.3125 5.34804 7.3125 4.3125C7.3125 4.13991 7.17259 4 7 4C6.82741 4 6.6875 4.13991 6.6875 4.3125C6.6875 5.34804 5.84804 6.1875 4.8125 6.1875C4.63991 6.1875 4.5 6.32741 4.5 6.5C4.5 6.67259 4.63991 6.8125 4.8125 6.8125C5.84804 6.8125 6.6875 7.65196 6.6875 8.6875C6.6875 8.86009 6.82741 9 7 9C7.17259 9 7.3125 8.86009 7.3125 8.6875C7.3125 7.65196 8.15196 6.8125 9.1875 6.8125C9.36009 6.8125 9.5 6.67259 9.5 6.5Z" fill="white" />
                    <path d="M9.5 6.5C9.5 6.32741 9.36009 6.1875 9.1875 6.1875C8.15196 6.1875 7.3125 5.34804 7.3125 4.3125C7.3125 4.13991 7.17259 4 7 4C6.82741 4 6.6875 4.13991 6.6875 4.3125C6.6875 5.34804 5.84804 6.1875 4.8125 6.1875C4.63991 6.1875 4.5 6.32741 4.5 6.5C4.5 6.67259 4.63991 6.8125 4.8125 6.8125C5.84804 6.8125 6.6875 7.65196 6.6875 8.6875C6.6875 8.86009 6.82741 9 7 9C7.17259 9 7.3125 8.86009 7.3125 8.6875C7.3125 7.65196 8.15196 6.8125 9.1875 6.8125C9.36009 6.8125 9.5 6.67259 9.5 6.5Z" fill="white" />
                    <path  d="M17 6C17 5.72386 16.7761 5.5 16.5 5.5C16.2239 5.5 16 5.72386 16 6C16 8.48488 13.9852 10.5 11.5 10.5C11.2239 10.5 11 10.7239 11 11C11 11.2761 11.2239 11.5 11.5 11.5C13.9853 11.5 16 13.5142 16 16C16 16.2761 16.2239 16.5 16.5 16.5C16.7761 16.5 17 16.2761 17 16C17 13.5141 19.0141 11.5 21.5 11.5C21.7761 11.5 22 11.2761 22 11C22 10.7239 21.7761 10.5 21.5 10.5C19.0142 10.5 17 8.48492 17 6Z" fill="white" />
                    <path d="M17 6C17 5.72386 16.7761 5.5 16.5 5.5C16.2239 5.5 16 5.72386 16 6C16 8.48488 13.9852 10.5 11.5 10.5C11.2239 10.5 11 10.7239 11 11C11 11.2761 11.2239 11.5 11.5 11.5C13.9853 11.5 16 13.5142 16 16C16 16.2761 16.2239 16.5 16.5 16.5C16.7761 16.5 17 16.2761 17 16C17 13.5141 19.0141 11.5 21.5 11.5C21.7761 11.5 22 11.2761 22 11C22 10.7239 21.7761 10.5 21.5 10.5C19.0142 10.5 17 8.48492 17 6Z" fill="white" />
                    <path  d="M8 12.5C8 12.2239 7.77614 12 7.5 12C7.22386 12 7 12.2239 7 12.5C7 14.1569 5.65686 15.5 4 15.5C3.72386 15.5 3.5 15.7239 3.5 16C3.5 16.2761 3.72386 16.5 4 16.5C5.65686 16.5 7 17.8431 7 19.5C7 19.7761 7.22386 20 7.5 20C7.77614 20 8 19.7761 8 19.5C8 17.8431 9.34314 16.5 11 16.5C11.2761 16.5 11.5 16.2761 11.5 16C11.5 15.7239 11.2761 15.5 11 15.5C9.34314 15.5 8 14.1569 8 12.5Z" fill="white" />
                    <path d="M8 12.5C8 12.2239 7.77614 12 7.5 12C7.22386 12 7 12.2239 7 12.5C7 14.1569 5.65686 15.5 4 15.5C3.72386 15.5 3.5 15.7239 3.5 16C3.5 16.2761 3.72386 16.5 4 16.5C5.65686 16.5 7 17.8431 7 19.5C7 19.7761 7.22386 20 7.5 20C7.77614 20 8 19.7761 8 19.5C8 17.8431 9.34314 16.5 11 16.5C11.2761 16.5 11.5 16.2761 11.5 16C11.5 15.7239 11.2761 15.5 11 15.5C9.34314 15.5 8 14.1569 8 12.5Z" fill="white" />
                </svg>
                <h1 className="text-[14px] text-white">Conheça a Melhor Base do Fivem!</h1>
            </div>
            <h1 className="Roboto title text-[72px] font-extrabold">BASE TNET</h1>
            <p className="title text-[18px] max-w-[657px] text-center sm:text-[16px]">Confira a mais nova e exclusiva base para FiveM ! Ofereça uma experiência inovadora e saia na frente, surpreendendo os jogadores que já estão cansados da mesmice. Aproveite para dar um upgrade no seu servidor com nossa base única!.</p>
        </div>
    )
}