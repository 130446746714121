import { useEffect, useState } from "react";
import Container from "../../Components/Container";
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import MachineList from "../../Components/MachineList";
import Modal from "../../Components/Modal";
import Statistics from "../../Components/Statistics";
import './style.css'
import Input from "../../Components/Input";
import { UseTnetCtx } from "../../Contexts/Context";

const ModalIp = ({ setisModalNewUser, setUsers, users, activeClient, handleInputChange, handleKeyDown, removeMachineUnity, generateToken, addMachineUnity, setModalNewMachine, setIpManagement, setActiveClient, resetForms, updateUserMachines, comercialProducts }) => {

    const activeUser = users?.filter((e) => e.id === activeClient)[0];
    let activeCopy = JSON.parse(JSON.stringify(activeUser))

    useEffect(() => {
         window.sendRequest("/api/machines/" + activeClient).then((data) => {
            setUsers((prevUsers) => {
                return prevUsers.map((user) => {
                    if (user.id === activeClient) {
                        return {
                            ...user,
                            Machines: data.map((data) => {
                                return { ...data, port: data.porta }
                            })

                        };
                    }
                    return user;
                });
            });
        }).catch(({ errorCode }) => {
            window.TriggerError(errorCode);
        }) 
    }, [])

    return (
        <div>
            <form onSubmit={console.log}>
                <div className="ipList max-h-[280px] overflow-auto">

                    {
                        [...activeCopy.Machines].map((machine, index) => {
                            return (
                                <div key={machine.token}>
                                    <label htmlFor='ip' className="text-[14px] text-[#344054] font-medium">Configuração</label>
                                    <div className="flex flex-col mb-5">
                                        <div className="flex flex-1 gap-2">
                                            <select onChange={(e) => handleInputChange(e, index, 'system')} required id="products" class="bg-gray-50 border  text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 lex-1  box-border px-3 py-2 mt-[6px] border-[#D0D5DD]">
                                                <option value="" disabled selected>Nenhum</option>

                                                {
                                                    
                                                    comercialProducts.map((e,key)=>{
                                                        let IsThat = (e.type=="script" ? "S" : "B") + e.label;

                                                        return (
                                                            <option selected={IsThat == machine.produto} Type={e.type} Name={e.label} >{e.label} ({e.type})</option>
                                                        )
                                                    })
                                                }
                                              
                                            </select>
                                            <button className={`w-[40px] h-[40px] rounded-lg box-border px-3 py-2 mt-[6px] border border-[#D0D5DD] flex items-center justify-center hover:cursor-pointer`}>
                                                <svg
                                                    viewBox="0 0 24 24"
                                                    fill="#667085"
                                                    height="1em"
                                                    width="1em"

                                                >
                                                    <path d="M5 22h14c1.103 0 2-.897 2-2V9a1 1 0 00-1-1h-3V7c0-2.757-2.243-5-5-5S7 4.243 7 7v1H4a1 1 0 00-1 1v11c0 1.103.897 2 2 2zM9 7c0-1.654 1.346-3 3-3s3 1.346 3 3v1H9V7zm-4 3h2v2h2v-2h6v2h2v-2h2l.002 10H5V10z" />
                                                </svg>
                                            </button>
                                        </div>
                                        <div className="defaultInput flex flex-1 gap-2">

                                            <input
                                                onChange={(e) => handleInputChange(e, index, 'ip')}
                                                onKeyDown={handleKeyDown}
                                                defaultValue={machine.ip}
                                                required={true} className="w-[267px] rounded-lg box-border px-3 py-2 mt-[6px] border-[#D0D5DD]" placeholder="Colocar aqui" /* onChange={onChange}  */ type='text' name='ip' />
                                            <input
                                                defaultValue={machine.port}
                                                onChange={(e) => handleInputChange(e, index, 'port')}
                                                type="number" maxLength={5} placeholder="30120" className="w-[85px] rounded-lg box-border px-3 py-2 mt-[6px] border-[#D0D5DD]" />
                                            <button onClick={(event) => removeMachineUnity(event, machine.token, index)} /* type="reset"  */ className={`w-[40px] h-[40px] rounded-lg box-border px-3 py-2 mt-[6px] border border-[#D0D5DD] flex items-center justify-center hover:cursor-pointer `}>

                                                <svg className="transform scale-150" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M5.75 7.75L6.59115 17.4233C6.68102 18.4568 7.54622 19.25 8.58363 19.25H14.4164C15.4538 19.25 16.319 18.4568 16.4088 17.4233L17.25 7.75H5.75Z" stroke="#667085" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                    <path d="M9.75 10.75V16.25" stroke="#667085" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                    <path d="M13.25 10.75V16.25" stroke="#667085" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                    <path d="M8.75 7.75V6.75C8.75 5.64543 9.64543 4.75 10.75 4.75H12.25C13.3546 4.75 14.25 5.64543 14.25 6.75V7.75" stroke="#667085" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                    <path d="M4.75 7.75H18.25" stroke="#667085" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                </svg>
                                            </button>
                                        </div>
                                        <div className="flex flex-1 gap-2">
                                            <input placeholder="Token" defaultValue={machine.token} disabled type="text" className="flex-1 rounded-lg box-border px-3 py-2 mt-[6px] border-[#D0D5DD]" />
                                            <button onClick={(event) => generateToken(event, index)} className={`w-[40px] h-[40px] rounded-lg box-border px-3 py-2 mt-[6px] bg-[#ff0000b3] border border-[#F00] flex items-center justify-center hover:cursor-pointer `}>
                                                <svg className="transform scale-150" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M11.25 14.75L8.75 17M8.75 17L11.25 19.25M8.75 17H13.25C16.5637 17 19.25 14.3137 19.25 11V10.75" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                    <path d="M15.25 7H10.75C7.43629 7 4.75 9.68629 4.75 13V13.25M15.25 7L12.75 9.25M15.25 7L12.75 4.75" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                </svg>
                                            </button>
                                        </div>

                                    </div>
                                </div>

                            )
                        })
                    }
                </div>


                <h1 className="flex gap-2 items-center text-[#D33D2C] font-semibold text-[14px] mt-2 hover:cursor-pointer" onClick={addMachineUnity}>
                    <span>
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                            <path d="M9.99996 4.16666V15.8333M4.16663 9.99999H15.8333" stroke="#D33D2C" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                    </span>
                    Adicionar novo campo de IP
                </h1>
                <div className="flex flex-1 gap-3 mt-8">
                    <button
                        onClick={() => {
                            setModalNewMachine(false)
                            setIpManagement(false)
                            setActiveClient('')
                            setisModalNewUser(false);
                        }}
                        type="reset" className="w-full h-[44px] border border-[#D0D5DD] rounded-lg bg-[FFF] text-[#344054] hover:bg-[#F9FAFB]">Voltar</button>
                    <button onClick={updateUserMachines} className="w-full h-[44px] border border-[#F00] rounded-lg bg-[#ff0000b2] text-white">Atualizar</button>
                </div>
            </form>
        </div>
    )
}

export default function Home() {

    const {
        userForms,
        setUserForms,
        resetForms,
        users,
        setUsers,
        isModalNewUser,
        setisModalNewUser,
        isModalNewMachine,
        setModalNewMachine,
        ipManagement,
        setIpManagement,
        activeClient,
        setActiveClient,
        sellers,
        setSellers,
        comercialProducts
    } = UseTnetCtx()



    const handleKeyDown = (e) => {
        // Allow only numbers and dots
        if (!/[0-9.]/.test(e.key) && e.key !== 'Backspace' && e.key !== 'ArrowLeft' && e.key !== 'ArrowRight' && e.key !== 'Delete' && e.key !== 'Tab') {
            e.preventDefault();
        }
    };

    useEffect(() => {
        //   console.log(JSON.stringify(users))
    }, [users])
    function uuidv4() {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    }
    const generateToken = (event, key) => { // Alig temporario
        event.preventDefault()

        let token = uuidv4();
        handleInputChange(event, key, 'token', token)

        return token;
    };

    const handleNewUser = (event) => {
        const { name, value } = event.target;
        setUserForms((prevForms) => ({
            ...prevForms,
            [name]: value
        }));
        window.editUser(activeClient, { [name]: value })
    }

    const registerUser = (event) => {
        event.preventDefault()
        setisModalNewUser(false)

        window.sendRequest("/api/newClient", {
            Email: userForms.Email,
            Nome: userForms.Nome,
            Telefone: userForms.Telefone,
            DiscordId: userForms.Discord,

        }).then(() => {
            resetForms()
            window.refreshUsers();
        }).catch(({ errorCode }) => {
            window.TriggerError(errorCode);
        })


    }

    const verifyClient = (event) => {
        event.preventDefault()

        let User = users.find((e) => e.Email == userForms.Email)
        if (User) {
            setUserForms((prevForm) => ({
                ...prevForm,
                Nome: User.Nome
            }))
            setActiveClient(User.id)
            setTimeout(() => {
                setIpManagement(true)
            }, 0);
        } else {
            // throw error
        }
    }

    const addMachineUnity = () => {
        setUsers((prevUsers) => {
            return prevUsers.map((user) => {
                if (user.id === activeClient) {
                    return {
                        ...user,
                        Machines: [
                            ...(user.Machines || []),
                            {

                                id: window.createMachine({ ip: '', port: 0, token: '', dono: activeClient }),
                                ip: '',
                                port: 0,
                                token: ''
                            }
                        ]
                    };
                }
                return user;
            });
        });
    };

    const handleInputChange = (e, machineIndex, field, forceValue) => {
        const { value } = e.target;
        setUsers((prevUsers) => prevUsers.map(user => {
            if (user.id === activeClient) {
                const updatedMachines = user.Machines?.map((machine, index) => {
                    if (index === machineIndex) {
                        if (field=="system") {
                          //  window.editMachine(machine.id, { [field]: forceValue || value })
                           let tg=[... e.target.querySelectorAll("option")].find((x)=>x.selected);
                            window.editMachine(machine.id, { "produto":tg.getAttribute("name")  })
                            window.editMachine(machine.id, { "produtoType":tg.getAttribute("type") })
                            
                        } else {
                            window.editMachine(machine.id, { [field]: forceValue || value })

                        }
                        return { ...machine, [field]: forceValue || value };
                    }
                    return machine;
                });
                return { ...user, Machines: updatedMachines };
            }
            return user;
        }));
    };

    const removeMachineUnity = (event, tokenToRemove, index) => {
        event.preventDefault()

        setUsers((prevUsers) => prevUsers.map(user => {
            if (user.id === activeClient) {
                let machine = user.Machines.find((machine, key) => machine.token ? machine.token === tokenToRemove : key == index)
                window.removeMachine(machine.id)
                return {
                    ...user,
                    Machines: user.Machines.filter((machine, key) => machine.token ? machine.token !== tokenToRemove : key != index)
                };
            }
            return user;
        }));
    };

    const updateUserMachines = (event) => {
        event.preventDefault()

        let idd = activeClient
        let array = users.filter((e) => e.id == idd)
        if (array) {
            setModalNewMachine(false)
            setIpManagement(false)
            setisModalNewUser(false);

            window.applyDrafts()


            // array é a tabela total, para ver as maquinas é array.Machines
        }
    }


    const handleClose = (show) => {
        if (!show) {
            window.clearDrafts();
            resetForms()
        }
    }

    const Nav = [
        {
            label: 'Home',
            route: '/'
        },
        {
            label: 'Dashboard',
            route: '/dashboard'
        },
        {
            label: 'Logs',
            route: '/logs'
        },
        /* {
            label: 'Status',
            route: '/'
        } */
    ]

    return (
        <>
            <Header navList={Nav} />
            <Container>

                <div className="w-full h-full flex flex-col items-center">
                    <div className="flex gap-2 mt-3">
                        <div className="flex machineContainer box-border p-[20px] w-[603px] rounded-xl gap-3 hover:cursor-pointer hover:border hover:border-[#ff000080]"
                            onClick={() => setisModalNewUser(true)}
                        >
                            <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect width="40" height="40" rx="20" fill="#FFEEEC" />
                                <path d="M20 22.9167H16.25C15.087 22.9167 14.5055 22.9167 14.0324 23.0602C12.967 23.3834 12.1334 24.217 11.8102 25.2824C11.6667 25.7555 11.6667 26.337 11.6667 27.5M25.8333 27.5V22.5M23.3333 25H28.3333M22.0833 16.25C22.0833 18.3211 20.4044 20 18.3333 20C16.2623 20 14.5833 18.3211 14.5833 16.25C14.5833 14.1789 16.2623 12.5 18.3333 12.5C20.4044 12.5 22.0833 14.1789 22.0833 16.25Z" stroke="#D33D2C" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                            <div>
                                <h1 className="font-semibold text-[#344054] text-[16px]">Cadastrar Cliente</h1>
                                <p className="text-[14px] text-secondaryColor">Crie um novo imovel</p>
                            </div>
                        </div>
                        <div className="flex machineContainer box-border p-[20px] w-[603px] rounded-xl gap-3 hover:cursor-pointer hover:border hover:border-[#ff000080]"
                            onClick={() => setModalNewMachine(true)}
                        >
                            <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect width="40" height="40" rx="20" fill="#FFEEEC" />
                                <path d="M20 22.9167H16.25C15.087 22.9167 14.5055 22.9167 14.0324 23.0602C12.967 23.3834 12.1334 24.217 11.8102 25.2824C11.6667 25.7555 11.6667 26.337 11.6667 27.5M25.8333 27.5V22.5M23.3333 25H28.3333M22.0833 16.25C22.0833 18.3211 20.4044 20 18.3333 20C16.2623 20 14.5833 18.3211 14.5833 16.25C14.5833 14.1789 16.2623 12.5 18.3333 12.5C20.4044 12.5 22.0833 14.1789 22.0833 16.25Z" stroke="#D33D2C" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                            <div>
                                <h1 className="font-semibold text-[#344054] text-[16px]">Cadastrar Máquina</h1>
                                <p className="text-[14px] text-secondaryColor">Crie um novo imovel</p>
                            </div>
                        </div>

                    </div>
                    <Statistics />
                    <MachineList />

                    <Modal title='Cadastro de Cliente' isOpen={isModalNewUser} setIsOpen={setisModalNewUser} backendMachine={true}>
                        <form onSubmit={registerUser}>
                            <Input
                                type='text'
                                label='Nome'
                                value={userForms?.Nome}
                                onChange={handleNewUser}
                                required={true}
                            />
                            <Input
                                type='email'
                                label='Email'
                                value={userForms?.Email}
                                onChange={handleNewUser}
                                required={true}
                            />
                            <Input
                                type='number'
                                label='Telefone'
                                value={userForms?.Telefone}
                                onChange={handleNewUser}
                                required={true}
                            />
                            <Input
                                type='number'
                                label='Discord'
                                value={userForms?.Discord}
                                onChange={handleNewUser}
                                required={true}
                            />
                            {
                                activeClient != '' ?
                                    <ModalIp
                                        setUsers={setUsers}
                                        setisModalNewUser={setisModalNewUser}
                                        users={users}
                                        activeClient={activeClient}
                                        handleInputChange={handleInputChange}
                                        handleKeyDown={handleKeyDown}
                                        removeMachineUnity={removeMachineUnity}
                                        generateToken={generateToken}
                                        addMachineUnity={addMachineUnity}
                                        setModalNewMachine={setModalNewMachine}
                                        setIpManagement={setIpManagement}
                                        setActiveClient={setActiveClient}
                                        comercialProducts={comercialProducts}
                                        resetForms={resetForms}
                                        updateUserMachines={updateUserMachines}
                                    />
                                    :
                                    <button type="submit" className="w-full h-[44px] border border-[#F00] rounded-lg bg-[#ff0000b2] text-white">Cadastrar</button>

                            }
                        </form>
                    </Modal>

                    <Modal title='Configuração de Máquina' isOpen={isModalNewMachine} setIsOpen={(open) => {
                        setIpManagement(open)
                        setModalNewMachine(open)
                    }}>
                        <form onSubmit={registerUser}>

                            <Input
                                type='email'
                                label='Email'
                                disabled={userForms?.Nome}
                                onChange={handleNewUser}
                                required={true}
                            />

                            <Input
                                type='Nome'
                                label='Nome'
                                value={userForms?.Nome}
                                onChange={handleNewUser}
                                disabled={true}
                                placeholder=" "
                            />
                            {
                                !ipManagement ?
                                    <div className="flex flex-1 gap-3">
                                        <button onClick={() => setModalNewMachine(false)} type="reset" className="w-full h-[44px] border border-[#D0D5DD] rounded-lg bg-[FFF] text-[#344054] hover:bg-[#F9FAFB]">Cancelar</button>
                                        <button onClick={verifyClient} className="w-full h-[44px] border border-[#F00] rounded-lg bg-[#ff0000b2] text-white">Confirmar cliente</button>
                                    </div>
                                    :
                                    <ModalIp
                                        setUsers={setUsers}
                                        setisModalNewUser={setisModalNewUser}
                                        users={users}
                                        activeClient={activeClient}
                                        handleInputChange={handleInputChange}
                                        handleKeyDown={handleKeyDown}
                                        removeMachineUnity={removeMachineUnity}
                                        generateToken={generateToken}
                                        comercialProducts={comercialProducts}
                                        addMachineUnity={addMachineUnity}
                                        setModalNewMachine={setModalNewMachine}
                                        setIpManagement={setIpManagement}
                                        setActiveClient={setActiveClient}
                                        resetForms={resetForms}
                                        updateUserMachines={updateUserMachines}
                                    />
                            }

                        </form>
                    </Modal>

                </div>
            </Container>
            <Footer />
        </>
    )
}
