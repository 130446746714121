import { useState } from "react"
import sendWebhook from "../../../Services/webhook"
import { ToastContainer, toast } from 'react-toastify';
export default function Leads(){

    const [contactForm, setContactForm] = useState({
        name: '',
        phone: '',
        discordId: '',
        projectDescription: ''
    })

    const handleAttribute = ({ attribute, value }) => {
        setContactForm((prev) => ({
            ...contactForm,
            [attribute]: value
        }))
    }

    const confirmForm = (e) =>{
        e.preventDefault()
      //  console.log('oi')
     //   console.log('Name',contactForm.name)
     //   console.log('Phone',contactForm.phone)
   //     console.log('discord id',contactForm.discordId)
      //  console.log('desc',contactForm.projectDescription)
        //A.LIG INTEGRAR DADOS ACIMA

        if (!contactForm.name || !contactForm.phone || !contactForm.discordId || !contactForm.projectDescription) {
            toast.error("Por favor, preencha todos os campos do formulário.")
            return; // Interrompe a execução se algum campo estiver vazio
        }
         window.sendRequest("/api/forms/sendFormFull",contactForm)
        .catch(({errorCode})=>{
                   window.TriggerError(errorCode);
        }) 

        sendWebhook('https://discord.com/api/webhooks/1332917349048651896/DR04f1x6wTa-M6g1T7DGf9B2isQ3QWfNPpEF2ZKEK2SrcUYmvwzmBcbp2hU-93zUt1HL',{
            Titulo : 'Confira Toda nossa Base',
            group : {
                Nome : contactForm.name,
                Telefone : contactForm.phone,
                Discord : `<@${contactForm.discordId}>`,
                Descricao : contactForm.projectDescription,
            }
        })

        setContactForm({
            name: '',
            phone: '',
            discordId: '',
            projectDescription: ''
        })

        toast("Aguarde o contato do nosso time de vendas!")
    }

    const inputStyle = "rounded-md border border-gray-300 bg-white shadow-[0px_1px_2px_rgba(16,24,40,0.05)]"
    const labelStyle = "text-[#344054] text-[14px]"
  
    return (
        <div id="leads" className="w-full flex flex-col mb-[100px] mt-[80px] sm:mt-[40px] sm:px-[30px]">
       
            <div className="flex gap-7 sm:flex-col">
                <img className="w-[519px] sm:w-full h-[348px] bg-red-300 rounded-lg sm:hidden object-cover" src="logo.png" alt="logo da base para fivem tnet" />
                    
              
                <form className="flex flex-col gap-3">
                    <div className="flex  sm:flex-col gap-3">
                        <div className="flex flex-col">
                            <label className={labelStyle} htmlFor="Nome">Nome</label>
                            <input className={inputStyle} type="text" name="Nome" placeholder="Nome"
                            onChange={(e) => handleAttribute({ attribute: 'name', value: e.target.value })}
                            />
                        </div>

                        <div className="flex flex-col">
                            <label className={labelStyle} htmlFor="DiscordID">Discord ID</label>
                            <input className={inputStyle} type="number" name="DiscordID" placeholder="157777777777777"
                                onChange={(e) => handleAttribute({ attribute: 'discordId', value: e.target.value })}
                            />
                        </div>
                    </div>

                    <label className={labelStyle} htmlFor="Phone">Telefone</label>
                    <input className={inputStyle} type="text" name="Phone" placeholder="(11) 94786-5578"
                        onChange={(e) => handleAttribute({ attribute: 'phone', value: e.target.value })}
                    />

                    <label className={labelStyle} htmlFor="Desc">Descrição do projeto</label>
                    <textarea className={`${inputStyle} h-[154px] resize-none`} type="text" name="Desc"
                         onChange={(e) => handleAttribute({ attribute: 'projectDescription', value: e.target.value })}
                    />
                </form>

            </div>
            <button onClick={confirmForm} className="btn w-full h-[45px] mt-[17px] rounded-lg text-white text-[18px] bg-[#ff0000bf]" type="submit">Reservar Base</button>

        </div>
    )
}