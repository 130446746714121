import { useState, useEffect } from 'react';

// Componente da estrela
const Star = () => (
    <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M10.2151 1.60996C10.3859 1.19932 10.9676 1.19932 11.1384 1.60996L13.2045 6.57744C13.2765 6.75056 13.4393 6.86885 13.6262 6.88383L18.989 7.31376C19.4324 7.3493 19.6121 7.90256 19.2743 8.19189L15.1884 11.6919C15.0461 11.8139 14.9839 12.0053 15.0274 12.1876L16.2757 17.4208C16.3789 17.8534 15.9082 18.1954 15.5287 17.9635L10.9374 15.1592C10.7774 15.0615 10.5761 15.0615 10.4161 15.1592L5.82482 17.9635C5.44527 18.1954 4.97464 17.8534 5.07784 17.4208L6.32614 12.1876C6.36965 12.0053 6.30746 11.8139 6.16507 11.6919L2.07917 8.19189C1.7414 7.90256 1.92117 7.3493 2.36449 7.31376L7.7273 6.88383C7.9142 6.86885 8.077 6.75056 8.14901 6.57744L10.2151 1.60996Z" fill="#FEC84B" />
    </svg>
);

// Dados dos feedbacks
const feedbackData = [
    { id: 1, author: "João Gomes", text: "Melhor Base que eu já adquiri, meus players adoram todos os sistemas imersivos! Recomendo super", company: "Tnet Roleplya" },
    { id: 2, author: "Maria Souza", text: "Excelente suporte e atenção aos detalhes! Meus jogadores estão amando!", company: "Game Hub" },
    { id: 3, author: "Lucas Pereira", text: "Muito bem estruturado e fácil de personalizar para qualquer jogo. Recomendo!", company: "Virtual Play" }
];

export default function Feedback() {
    const [currentFeedback, setCurrentFeedback] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentFeedback((prev) => (prev + 1) % feedbackData.length);
        }, 3000);

        return () => clearInterval(interval);
    }, []);

    const feedback = feedbackData[currentFeedback];

    return (
        <div className="w-full h-[459px] sm:h-[350px] bg-[#ff0000b2] flex items-center justify-center text-white mt-[94px]">
            <div
                className="flex w-[1112px] sm:w-[85%] md:w-[80%] lg:w-[80%] h-[299px] sm:h-[250px] bg-[#ffffff33] rounded-2xl overflow-hidden transition duration-500 transform-gpu hover:scale-105 hover:shadow-xl"
                style={{
                    opacity: 0.9,
                    transition: 'opacity 0.5s ease, transform 0.5s ease',
                    transform: 'scale(1)',
                    animation: `fade-in-out 3s ease infinite`,
                }}
            >
                <style jsx>{`
                    @keyframes fade-in-out {
                        0%, 100% { opacity: 0; transform: scale(0.95); }
                        50% { opacity: 1; transform: scale(1); }
                    }
                `}</style>
                
                <div className="flex flex-col justify-center w-[722px] sm:w-[500px] box-border px-[50px] gap-[7px]">
                    <div className="flex">
                        {[...Array(5)].map((_, i) => (
                            <Star key={i} />
                        ))}
                    </div>
                    <h1 className="text-[28px] sm:text-[12px] leading-8 sm:leading-[14px]">{feedback.text}</h1>
                    <div className="flex author items-center">
                        <h1 className="text-[18px] sm:text-[10px]">{feedback.author}</h1>
                    </div>
                    <h2 className="text-[14px] sm:text-[12px] opacity-75">{feedback.company}</h2>
                </div>

                {/* Logo */}
                <div className="w-[390px] h-full bg-red-500 flex items-center justify-center">
                    <img src="logo2.svg" alt="Logo da base de fivem tnet" />
                </div>
            </div>
        </div>
    );
}
