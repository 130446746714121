import Header from "../../Components/Header";
import Container from "../../Components/LP/Container";
import Feedback from "../../Components/LP/Feedback";
import Counter from "../../Components/LP/Counter";
import Player from "../../Components/LP/Player";
import Title from "../../Components/LP/Title";
import Fixed from "../../Components/LP/Fixed";
import Article from "../../Components/LP/Article";
import Card from "../../Components/LP/Card";
import Queue from "../../Components/LP/Queue";
import Modal from "../../Components/Modal";
import Input from "../../Components/Input";
import { useContext, useEffect, useState } from "react";
import ImageGallery from "react-image-gallery";
import Footer from "../../Components/Footer";
import FAC from "../../Components/LP/FAC";
import Leads from "../../Components/LP/Leads";
import { TnetContext } from "../../Contexts/Context";
import { ToastContainer, toast } from 'react-toastify';
import sendWebhook from "../../Services/webhook";
import {Helmet} from "react-helmet";

const ContactIcon = () => {

    const {
        gerarLinkWhatsApp,
    } = useContext(TnetContext)

    return (
     
        <a href={gerarLinkWhatsApp('Ola, tenho interesse em diferenciar meu servidor com a TNET')} 
        className="btn fixed right-[-30px] bottom-[70px] sm:bottom-[105px] m-[50px] z-[102] w-[55px] h-[55px] bg-[#ff0000b2] rounded-2xl">
            <img src="wpp.png" alt="Icone do whatsApp"  />
        </a>

    )

}

export default function Landing() {
    const {
        infoCards
    } = useContext(TnetContext)

    const [dialog, setDialog] = useState(false)

    const [contactForm, setContactForm] = useState({
        name: '',
        phone: '',
        discordId: ''
    })

    const handleAttribute = ({ attribute, value }) => {
        setContactForm((prev) => ({
            ...contactForm,
            [attribute]: value
        }))
    }

    const images = [
        {
            original: "https://picsum.photos/id/1018/1000/600/",
            thumbnail: "https://picsum.photos/id/1018/250/150/",
        },
        {
            original: "https://picsum.photos/id/1015/1000/600/",
            thumbnail: "https://picsum.photos/id/1015/250/150/",
        },
        {
            original: "https://picsum.photos/id/1019/1000/600/",
            thumbnail: "https://picsum.photos/id/1019/250/150/",
        },
        {
            original: "https://picsum.photos/id/1015/1000/600/",
            thumbnail: "https://picsum.photos/id/1015/250/150/",
        },
        {
            original: "https://picsum.photos/id/1019/1000/600/",
            thumbnail: "https://picsum.photos/id/1019/250/150/",
        },
    ];


    useEffect(() => {
        console.log(contactForm)
    }, [contactForm])
    
    const Nav = [
        {
            label: 'Home',
            route: '/'
        },
        {
            label: 'Scripts',
            route: '/products'
        },
       
        /* {
            label: 'Status',
            route: '/status'
        } */
    ]


    const submitForm = (e)=>{
        e.preventDefault()
      //  console.log('oi')
      //  console.log('Name',contactForm.name)
      //  console.log('Phone',contactForm.phone)
      //  console.log('discord id',contactForm.discordId)
        
      if (!contactForm.name || !contactForm.phone || !contactForm.discordId) {
        toast.error("Por favor, preencha todos os campos do formulário.")
        return; 
    }

        window.sendRequest("/api/forms/sendFormFull2",contactForm)
        .catch(({errorCode})=>{
                   window.TriggerError(errorCode);
        })
        setDialog(false)

        sendWebhook('https://discord.com/api/webhooks/1332917349048651896/DR04f1x6wTa-M6g1T7DGf9B2isQ3QWfNPpEF2ZKEK2SrcUYmvwzmBcbp2hU-93zUt1HL',{
            Titulo : 'Popup',
            group : {
                Nome : contactForm.name,
                Telefone : contactForm.phone,
                Discord : `<@${contactForm.discordId}>`
            }
        })
        toast("Aguarde o contato do nosso time de vendas!")

        setContactForm({
            name: '',
            phone: '',
            discordId: ''
        })
    }
   
    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <meta name="robots" content="index, follow" />
                <title>Base TNET - A melhor Base Para FiveM | Completa e Otimizada</title>
                <link rel="canonical" href="https://basetnet.com.br" />
                <meta name="description" content="A melhor base para FiveM com desempenho otimizado e pronta para uso! Configure seu servidor rapidamente com suporte da Trigueiro Tech." />
                <meta name="keywords" content="base para FiveM, FiveM otimizado, servidor FiveM, configuração FiveM, scripts fivem, base servidor rp, base RP, gta rp, complexo, cidade alta, fluxo, trigueiro, curso fivem" />
                <meta property="og:title" content="Base TNET - A melhor Base Para FiveM | Completa e Otimizada" />
                <meta property="og:description" content="A melhor base para FiveM com desempenho otimizado e pronta para uso! Configure seu servidor rapidamente com suporte da Trigueiro Tech." />
                <meta property="og:image" content="https://r2.fivemanage.com/4H9r1BIXAzb8b3Iyexjyo/images/logo.png" />
                <meta property="og:url" content="https://basetnet.com.br" />
                <meta property="og:type" content="website" />
                <link rel="alternate" hreflang="pt-BR" href="https://basetnet.com.br" />
            </Helmet>
            <ToastContainer />
           
            <ContactIcon />
            <Header navList={Nav} />
            <Container>
                <Title />
                <Player />
                <Article />
            </Container>
            <Feedback />
            <Container>
                <div className="mt-[54px]">
                    {
                        infoCards.map((e,key)=>{
                            return (
                                <Card 
                                    orientation={(key % 2 === 0) ? 'right' : 'left'}
                                    data = {e} 
                                />
                            )
                        })
                    }
                 
                </div>
            </Container>
            <Queue />

            <Counter />
            <Container>
                <div className="w-full flex items-center flex-col mt-[84px]">
                    <h1 className="text-center title text-[24px] font-semibold">Confira Toda Nossa Base</h1>
                    <p className="text-center text-[#475467] text-[16px] font-normal max-w-[717px]">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's s</p>

                </div>
                <ImageGallery
                    items={images}
                    showThumbnails={false}
                    autoPlay={true}
                    bulletClass={true}
                    showPlayButton={false}
                    showFullscreenButton={false}
                    showBullets={true}
                    disableKeyDown={true}
                    additionalClass="!rounded-[50px] mt-[30px] !w-full"
                />

                <div>
                    <div className="w-full flex items-center flex-col mt-[84px]">
                        <h1 className="text-center title text-[24px] font-semibold">Perguntas Frequentes</h1>
                        <p className="text-center text-[#475467] text-[16px] font-normal max-w-[717px]">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's s</p>

                    </div>

                   <FAC />
                </div>
                <div className="w-full flex items-center flex-col mt-[84px]">
                    <h1 className="text-center title text-[24px] font-semibold">Confira Toda Nossa Base</h1>
                    <p className="text-center text-[#475467] text-[16px] font-normal max-w-[717px]">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's s</p>

                </div>
                <Leads />
            </Container>


            <Fixed action={() => setDialog(true)} />

            <Modal title='Formulario de interesse' isOpen={dialog} setIsOpen={setDialog} backendMachine={true}>
                <p className="title text-[14px]">A <span className="font-bold">TNET</span> está com fila de espera para versao <span className="font-bold">ALPHA</span></p>
                <form className="mt-[15px]" onSubmit={() => console.log('oi')}>
                    <Input
                        type='text'
                        label='Nome'
                        onChange={(e) => handleAttribute({ attribute: 'name', value: e.target.value })}
                        required
                    />
                    <Input
                        type='text'
                        label='Telefone'
                        onChange={(e) => handleAttribute({ attribute: 'phone', value: e.target.value })}
                        required
                    />
                    <Input
                        type='number'
                        label='ID Discord'
                        onChange={(e) => handleAttribute({ attribute: 'discordId', value: e.target.value })}
                        required
                    />
                    <button onClick={submitForm} type="submit" className="btn w-full h-[44px] border border-[#F00] rounded-lg bg-[#ff0000b2] text-white">Solicitar Contato</button>
                </form>
            </Modal>

            <Footer />
        </div>
    )
}